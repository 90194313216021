/* *{
    margin:0;
    padding:0;
    box-sizing:border-box;
}
background:linear-gradient(to right top,#65dfc9a6,#6cdaeba6); */
main{
    min-height: 100vh;
    /* background:linear-gradient(to right top,#087CFA, #459bf7); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content :space-between; */
}

.main-light {
    background: #fff;
    color: #323337 !important;
}

.main-dark {
    background: #323337;
    color: #fff !important;
}
.mainauth-light {
    background: #D4DBFF;
    color: #323337 !important;
}
.mainauth-dark {
    /* background: #D4DBFF; */
    background: #161719;
    color: #818284 !important;
}
.fond_back-light {
    background: #D4DBFF;
}

.fond_back-dark {
    background: #161719;
}

.footer{
    padding-bottom: 5px;
    min-height: 250px;
    padding-bottom: 20px;
}

.footer-light{
    background: #D4DBFF !important;
}

.footer-dark{
    background: #161719 !important;
}

.lienHyperText:hover > .lienHyperText-icon{
    fill: white !important;
    color: white !important;
}

.lienHyperText:hover{
    background: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
    color: white;
    cursor: pointer;
}

.root_bleu_svg {
    fill: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.fs_17{
    font-size: 17px !important;
}

.root_bleu {
    color: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
}

.buttonEl:hover > .iconEl{
    fill: white !important;
}

.buttonEl_dark:hover > .inter .iconEl_dark{
    fill: white !important;
}

.buttonEl:hover{
    background: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
    color: white;
    cursor: pointer;
}

.iconEl_dark{
    fill: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.buttonEl_dark:hover > .iconEl_dark{
    fill: black !important;
}

.buttonEl_dark:hover > .inter_dar .iconEl_dark{
    fill: black !important;
}

.buttonEl_dark:hover{
    background: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
    color: black;
    cursor: pointer;
}

.glass{
    padding-bottom: 50px;
    min-width: 60%;
    background: rgba(244,246,255,.5);
    border-radius: 2rem;
    z-index: 2;
    background: #E4E8FF;

}

.glass-dark{
    background: rgba(244,246,255,.5);
    background: #323337;
}

.glass-light{
    background: rgba(244,246,255,.5);
    background: #E4E8FF;
}

.ty-text{
    font-weight: 700;
    font-size: 1.5rem;
}

.ty-text-light{
    color: #5F6368;
}

.ty-text-dark{
    color: #FFF;
}

/* .contenaire{
    padding: 3rem;
   }
} */

.ty-text-right{
    color: #90959b;
    font-weight: 700;
    font-size: 1rem;
}



.sub-ty-text{
    display: block;
    color: #90959b;
    font-weight: 700;
    font-size: 1rem;
    /* margin: 1.5rem; */
}

.sub-ty-text-gray{
    color: #90959b;
}

.circle{
    background: white;
    background:linear-gradient(
            to right bottom,
            rgba(255,255,255,.6),
            rgba(255,255,255,.1)
    );
    height: 3.5rem;
    width: 3.5rem;
    position: absolute;
    border-radius: 50%;
}

.circle-dark{
    background: white;
    background:linear-gradient(
            to right bottom,
            rgba(255,255,255,.6),
            rgba(255,255,255,.1)
    );
    background:#24252763;
}

.circle-light{
    background: white;
    background:linear-gradient(
            to right bottom,
            rgba(255,255,255,.6),
            rgba(255,255,255,.1)
    );
}

.circle1{
    top:5%;
    right:15%;
    /* background: red; */
}
.circle2{
    bottom:35%;
    left:5%;
}
.circle3{
    bottom:5%;
    left:15%;
}

.circle4{
    top:1%;
    right:35%;
    /* background: red; */
}
.circle5{
    bottom:1%;
    left:35%;
}
.circle6{
    top:35%;
    right:5%;
}

/*
ici css for input
 */

@font-face {
    font-family: MyFont;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
}

input[type='text']{
    font-size: 0.89rem;
}

* {
    font-family: MyFont, serif;
}